<template>
  <div class="nav">
    <div class="nav-left">
      <img :src="require('@/assets/nav-icon.svg')" class="left-img" />
      <span>{{ title }}</span>
    </div>
    <div class="nav-right" @click="getMore(id)">
      <span>更多</span>
      <CaretRightSmallIcon style="color: #777777" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs } from "vue"
import { CaretRightSmallIcon } from 'tdesign-icons-vue-next';
import { useRouter } from "vue-router";

const router = useRouter();
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  }
});
const { title, id } = toRefs(props);

const getMore = (id) => {
  // router.push(`/news?id=${id}&title=${title.value}`);
  window.open(
    router.resolve({
      name: 'news',
      query: {
        id: id,
        title: title.value
      }
    }).href,
    '_blank'
  )
}


</script>
<style lang="scss" scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: Sans Bold;
  .nav-left{
    color: #1A1A1A;
    font-size: 24px;
    line-height: 24px;

    .left-img{
      margin-right: 8px;
    }
  }
  .nav-right{
    color: #999999;
    cursor: pointer;
  }
}
</style>
