<template>
  <div class="footer">
    <div class="footer-detail">
      <div class="footer-top">
        <span>联系我们</span>
        <span>关注我们</span>
      </div>
      <div class="footer-middle">
        <div class="left">
          <img :src="require('@/assets/time.svg')" class="icon" />
          <span class="time">周一至周五 09:00-18:00</span>
          <img :src="require('@/assets/phone.svg')" class="icon" />
          <span>027-67287652</span>
        </div>
        <div>
          <t-space :size="20">
            <img :src="require('@/assets/weChat.svg')" />
            <img :src="require('@/assets/microblog.svg')" />
          </t-space>
        </div>
      </div>
      <div class="footer-middle footer-bottom">
        <div>
          <img :src="require('@/assets/map-pin.svg')" class="icon" />
          <span class="time">武汉市洪山区融创智谷B2栋8楼</span>
          <img :src="require('@/assets/mail.svg')" class="icon" />
          <span>husdid@zxhj.com</span>
        </div>
        <span class="mark">备案号：<a href="https://beian.miit.gov.cn">鄂ICP备2022001624号-1</a></span>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 170px;
  background: #242424;
  font-family: Sans Medium;
  // margin-top: 24px;
  .footer-detail {
    width: 1200px;
    height: 100%;
    margin: 0px auto;
    overflow: hidden;
    padding: 33px 0;
    box-sizing: border-box;
    .footer-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }

    .footer-middle {
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      font-weight: 500;
      margin-top: 24px;

      .left {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      .time {
        min-width: 194px;
        margin-right: 38px;
      }
    }

    .footer-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 6px;
      & > div {
        display: flex;
        align-items: center;
      }
      .mark {
        color: #fff;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
