import request from "@/api/axios";
import { apiList as api } from "./apiList";

 // 根据token查询用户信息
export function getTokenUser(token) {
  return request({
    url: api.user.getTokenUser,
    method: "get",
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    noAuthor: true,
    params: {
      clientId: 'common',
      token
    }
  });
}

// 根据id获取帐号信息
export function getAccountInfo(userId) { 
  return request({
    url: api.user.getAccountInfo + userId,
    method: "get"
  });
}

// 根据id获取个人信息
export function getUserInfo(userId) { 
  return request({
    url: api.user.getUserInfo + userId,
    method: "get"
  });
}

// 退出登录
export function getSignOut() {
  return request({
    url: api.user.getSignOut,
    method: "delete"
  });
}

// 验证token是否有效
export function getCheckToken(token) {
  return request({
    url: api.user.getCheckToken,
    method: "get",
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    noAuthor: true,
    params: {
      token
    }
  });
}

export function getVipHeader(userId) {
  return request({
    url: api.user.getVipHeader + userId, // 获取会员头像框
    method: "get"
  });
}