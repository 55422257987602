<template>
  <div v-if="info.itemList && info.itemList.length" class="column">
    <t-divider  />
    <baseNav :title="info.title" :id="info.id"></baseNav>
    <div class="column-list">
      <div v-for="(item,index) in info.itemList.slice(0,8)" :key="item.id" class="list-item" @click="toDetailFn(item)">
        <div class="item">
          <!-- <img :src="item.cover" class="item-img"/> -->
          <t-image
            :src="item.cover"
            fit="cover"
            position="center"
            class="item-img"
          />
          <div class="item-info">
            <div class="item-info-title"><span v-if="item.label" class="item-info-lable">{{ item.label }}</span>{{ item.articleTitle }}</div>
            <div class="item-info-pub">
              <span>{{ item.creator }}</span>
              <span class="item-time">{{ formatTimestamp(item.publishTime) }}</span>
            </div>
          </div>
        </div>
         <t-divider v-if=" (index +1) % 4 !== 0 && info.itemList.length > (index + 1) " layout="vertical"  />
      </div>
    </div>
  </div>
</template>

<script setup>
/* 组件 */
import baseNav from './baseNav'

/* 工具 */
import { ref, defineProps, toRefs } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();
import { formatTimestamp } from '@/util/validate';

const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  }
});
const { info } = toRefs(props);

const toDetailFn = (item) => {
    // 链接
  if (item.articleType) {
    item.articleContent && window.open(item.articleContent)
  } else {
    // 新闻
    window.open(router.resolve({ name: 'newsDetail',query: { id: item.id }}).href, '_blank')
  }
}
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  font-family: Sans Bold;
  .column-list{
    width: 100%;
    display: grid;
    grid-template-columns: 308px 308px 308px 267px;
      .list-item{
        display: flex;
        margin-top: 24px;
        cursor: pointer;
        width: fit-content;
        .item{
          width: 267px;
          height: 299px;
          border-radius: 4px;
        }
        .item-img{
          width: 100%;
          height: 150px;
          border-radius: 4px;
        }
        .item-info{
          font-family: Sans Bold;
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          color: #1A1A1A;
          .item-info-title{
            height: 90px;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; 
            margin-bottom: 17px;
            margin-top: 16px;
            line-height: 30px;
            .item-info-lable{
              padding: 2px 4px;
              box-sizing: border-box;
              background: #FF3737;
              border-radius: 4px;
              line-height: 18px;
              color: #FFFFFF;
              font-size: 12px;
              margin-right: 2px;
              display: inline-block;
            }
          }
          .item-info-pub{
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            line-height: 26px;
            color: #999999;
            .item-time{
              color: #999999;
              font-family: Sans Medium;
            }
          }
        }
        :deep .t-divider--vertical{
          height: 299px;
          margin: 0 20px;
          border-left: 1px solid rgba(170, 170, 170, 0.2);
        }
      }
      .list-item:nth-child(4n){
         margin-right: 0px;
      }
  }
}
</style>
