export const apiList = {
  //首页
  home: {
    getHomepage: "/general/homePartItem/homepage", // 首页数据列表查询(未登录)
    getNews: '/general/homePartItem/getListByPartId/', //根据栏目查询文章列表（未登录）
    getHot: '/general/homePartItem/ranking', //热门排行列表查询（未登录）
    getNewsDetail: "/general/homePartItem/getInfoById", //根据文章ID查看详情（未登录）
    recordAccess: '/general/homePartItem/recordAccess/',//记录文章访问量（未登录）
    getGroupList: '/group/home/list', //首页群组列表
    joinGroup: '/group/member/join', //加入指定群组
    getCover: '/general/cover', //根据类型获取封面

    getApplication: '/admin/app/online/listAll', //用户端应用列表(无登录状态)
    detailAndStatus: '/admin/app/detailAndStatus/', //应用访问权限获取无需鉴权
    detailAndStatusAuth: '/admin/app/detailAndStatusLgd/', //应用访问权限获取需鉴权
    createApp: '/admin/user/app/', //用户应用开通
    appLogs: '/admin/app/logs/', //应用升级日志列表
    strategyApp: '/vip/strategy/forApp'  //查询应用会员等级信息

  },
  user: {
    getTokenUser: "/auth/token/query-token", //根据token查询用户信息
    getAccountInfo: "/admin/user/getById/", // 根据id获取帐号信息
    getUserInfo: "/admin/user/getPersonalInformation/", // 根据id获取个人信息
    getSignOut: "/auth/token/logout", // 退出登录
    getCheckToken: "/auth/oauth/check_token", // 验证token是否有效
    getVipHeader: "/vip/membershipHeader/" // 获取会员头像框
  },
  vipService: {
    getPeriod: '/vip/strategy/period',
    getSubject: '/vip/strategy/subject',
    getVersion: '/vip/strategy/tutorialVersion',
    getMixInfo: '/vip/strategy/aggregation',
    getHadServices: '/vip/strategy/resourceAuth/',
    getServiceInfo: '/admin/app/online/listAll',
    getMemberInfo: '/vip/membership/auth/',
    placeOrder: '/vip/order/placeOrder',
    getOrderInfo: '/pay/TradeOrder/info'
  }
}
