import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/home/Home.vue";
import Layout from '@/component/layout.vue';
const basename = "";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        title: '首页',
        component: Home
      },
      {
        path: '/resource',
        name: 'resource',
        title: '资源',
        component: () => import("@/views/resource"),
      },
      /* {
        path: '/member',
        name: 'member',
        title: '会员',
        component: () => import("@/views/member"),
      },
      {
        path: '/group',
        name: 'group',
        title: '群组',
        component: () => import("@/views/group"),
      },
      {
        path: '/application',
        name: 'application',
        title: '应用',
        component: () => import("@/views/application"),
      }, */


      {
        path: '/news',
        name: 'news',
        title: '文章列表',
        component: () => import("@/views/news/index"),
      },
      
    ]
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    title: '文章详情',
    component: () => import("@/views/news/detail"),
  },
  /* {
    path: '/appLog',
    name: 'appLog',
    title: '应用日志',
    component: () => import("@/views/application/appLog"),
  },
  {
    path: '/detail',
    name: 'detail',
    title: '应用详情',
    component: () => import("@/views/application/detail"),
  },
  {
    path: "/choiseVip",
    name: "选择服务",
    component: () => import("../views/vipService/choiseVip/index.vue"),
  },
  {
    path: "/detailVip",
    name: "服务详情",
    component: () => import("../views/vipService/detailVip/index.vue"),
  },
  {
    path: "/payment",
    name: "支付",
    component: () => import("../views/vipService/payment/index.vue"),
  }, */
];

const router = createRouter({
  history: createWebHistory(basename),
  routes,
});

export default router;
