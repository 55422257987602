<template>
  <div v-if="info.itemList && info.itemList.length" class="column">
    <t-divider />
    <baseNav :title="info.title" :id="info.id"></baseNav>
    <div  class="column-main">
      <div  class="main-left" @click="toDetailFn(info.itemList[0])">
        <t-image
          :src="info.itemList[0].cover"
          fit="cover"
          position="center"
          class="main-left-img"
        />
        <div class="main-left-title">{{ info.itemList[0].articleTitle }}</div>
        <div class="main-left-time">{{ formatTimestamp(info.itemList[0].publishTime) }}</div>
      </div>
      <div v-if="info.itemList.length > 1" class="main-right">
        <div class="article" @click="toDetailFn(info.itemList[1])">
          <t-image
            :src="info.itemList[1].cover"
            fit="cover"
            position="center"
            class="article-img"
          />
          <div class="article-title">{{ info.itemList[1].articleTitle }}</div>
        </div>
        <div v-if="info.itemList.length > 2" class="article article-bottom" @click="toDetailFn(info.itemList[2])">
          <t-image
            :src="info.itemList[2].cover"
            fit="cover"
            position="center"
            class="article-img"
          />
          <div class="article-title">{{ info.itemList[2].articleTitle }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* 组件 */
import baseNav from './baseNav'

/* 工具 */
import { ref, defineProps, toRefs } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();
import { formatTimestamp } from '@/util/validate';

const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  }
});
const { info } = toRefs(props);

const toDetailFn = (item) => {
   // 链接
  if (item.articleType) {
    item.articleContent && window.open(item.articleContent)
  } else {
    // 新闻
    window.open(router.resolve({ name: 'newsDetail',query: { id: item.id }}).href, '_blank')
  }
}
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  font-family: Sans Bold;
 
  .column-main{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .main-left{
      width: 845px;
      height: 473px;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      .main-left-img{
        width: 845px;
        height: 473px;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .main-left-title{
        position: absolute;
        bottom:0;
        left: 0;
        width: 100%;
        height: 91px;
        border-radius: 0px 0px 4px 4px;
        background: rgba(15, 15, 15, 0.9);
        color: #FFFFFF;
        font-weight: bold;
        font-family: Sans Bold;
        font-size: 18px;
        line-height: 30px;
        padding: 15px 0 0 20px;
        box-sizing:border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 10;
      }
      .main-left-time{
        font-size: 14px;
        color: #fff;
        font-family: Sans Medium;
        line-height: 30px;
        margin-top: 5px;
        position: absolute;
        bottom: 16px;
        left: 19px;
        z-index: 10;
      }
    }

    .main-right{
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      width: 339px;

      .article{
        width: 100%;
        height: 270px;
        position: relative;
         cursor: pointer;
        .article-img{
          width: 100%;
          height: 100%;
          border-radius: 4px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .article-title{
          position: absolute;
          bottom:0px;
          left: 0;
          width: 100%;
          height: 71px;
          border-radius: 0px 0px 4px 4px;
          background: rgba(15, 15, 15, 0.9);
          color: #FFFFFF;
          font-weight: bold;
          font-family: Sans Bold;
          font-size: 18px;
          line-height: 30px;
          padding: 5px 18px;
          box-sizing:border-box;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; 
          z-index: 10;
        }
      }

      .article-bottom{
        height: 188px;
        .article-title{
          bottom: 0px;
        }
      }
    }
  }
}
</style>
