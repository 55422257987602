<template>
  <div class="layout">
    <NavBar />
    <router-view class="middle-content" />
    <Footer v-show="!['/member','/resource'].includes(routePath)"/>
  </div>
</template>

<script setup>
/* 公共头部尾部组件 */
import NavBar from './navBar';
import Footer from './footer';

import { watch ,ref} from 'vue';
import { useRoute } from "vue-router";
const route = useRoute()
const routePath = ref('')
watch(
  () => route.path,
  (value) => {
    routePath.value = value
  },
  {
    deep: true,
    immediate: true
  }
)
</script>
<style lang="scss" scoped>
.layout {
  box-sizing: border-box;
  height: 100vh;
  // overflow-y: scroll;
}
.middle-content{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 240px);
  
}
</style>
