<template>
  <!-- 轮播图 -->
  <div v-if="info.rotoList && info.rotoList.length">
    <t-divider />
    <t-swiper class="swiper" :autoplay="true" :duration="300" :interval="2000" :navigation="{ showSlideBtn: 'never' }" :direction="direction">
      <t-swiper-item v-for="item in info.rotoList" :key="item.id" @click="toDetailFn(item.jumpLink)" class="swiper-item">
        <img :src="item.url" class="swiper-img"/>
      </t-swiper-item>
    </t-swiper>
  </div>
</template>

<script setup>
/* 工具 */
import { ref, defineProps, toRefs, computed } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  }
});
const { info } = toRefs(props);
const direction = computed(() => {
  // 轮播方向
  return  info.value.shape ? 'vertical' : 'horizontal';
 
});
const toDetailFn = (url) => {
  url && window.open(url)
}
</script>
<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: 200px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;

  :deep .t-swiper__container {
    height:200px!important;
  }
  .swiper-item{
    width: 1200px;
    height: 200px;
    border-radius: 4px;
     cursor: pointer;
    .swiper-img{
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
}
</style>
