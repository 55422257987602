<template>
  <!-- 头部栏目 -->
  <div v-if="info.itemList && info.itemList.length" class="common">
    <t-swiper v-if="info.itemList" class="swiper" :autoplay="false" :duration="300" :interval="2000" :navigation="{ showSlideBtn: 'never' }">
      <t-swiper-item v-for="item in info.itemList.slice(0,5)" :key="item.id" @click="toDetailFn(item)">
        <div class="swiper-item">
          <!-- <img :src="item.cover" class="swiper-img"/> -->
          <t-image
            :src="item.cover"
            fit="cover"
            position="center"
            class="swiper-img"
          />
          <div class="swiper-title">
            <span v-if="item.label" class="item-info-lable">{{ item.label }}</span>
            <span class="item-article">{{ item.articleTitle }}</span>
          </div>
        </div>
      </t-swiper-item>
    </t-swiper>
    <div class="common-right">
      <div v-for="item in info.itemList.slice(5,9)" :key="item.id" class="list-item" @click="toDetailFn(item)">
        <!-- <img :src="item.cover" class="item-img"/> -->
        <t-image
          :src="item.cover"
          fit="cover"
          position="center"
          class="item-img"
        />
        <div class="item-info">
          <div class="item-info-title"><span v-if="item.label" class="item-info-lable">{{ item.label }}</span>{{ item.articleTitle }}</div>
          <div class="item-info-pub">
            <span>{{ item.creator }}</span>
            <span>{{ formatTimestamp(item.publishTime) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* 工具 */
import { ref, defineProps, toRefs } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();
import { formatTimestamp } from '@/util/validate';

const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  },
  
});
const { info } = toRefs(props);

const toDetailFn = (item) => {
  // 链接
  if (item.articleType) {
    item.articleContent && window.open(item.articleContent)
  } else {
    // 新闻
    window.open(router.resolve({ name: 'newsDetail',query: { id: item.id }}).href, '_blank')
  }
}
</script>
<style lang="scss" scoped>
.common {
  display:flex;
  margin-top: 24px;
  width: 100%;
  font-family: Sans Bold;
 
  .swiper{
    width: 744px;
    height: 430px;
    margin-right: 16px;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
  }
  :deep .t-swiper__navigation-bars .t-swiper__navigation-item{
    width: 132px;
    span{
      background: #D8D8D8;
      height: 6px;
      border-radius: 6px;
    }
  }
  :deep .t-swiper__navigation-bars .t-swiper__navigation-item.t-is-active{
    width: 132px;
    span{
      background: #2A5FF0;
    }
  } 
  .swiper-item{
    width: 744px;
    height: 430px;
    // margin-right: 16px;
    position: relative;
    cursor: pointer;
    border-radius: 4px;

    .swiper-img{
      width: 744px;
      height: 430px;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .swiper-title{
      position: absolute;
      bottom:0;
      left: 0;
      width: 100%;
      height: 128px;
      border-radius: 0px 0px 4px 4px;
      background: rgba(15, 15, 15, 0.9);
      display: flex;
      align-items: center;
      color: #FFFFFF;
      
      font-family: Sans Bold;
      padding: 25px 0 0 20px;
      z-index: 10;
       .item-info-lable{
        padding: 2px 4px;
        box-sizing: border-box;
        background: #FF3737;
        border-radius: 4px;
        line-height: 18px;
        color: #FFFFFF;
        font-size: 12px;
        margin-right: 2px;
        display: inline-block;
      }
      .item-article{
        flex: 1;
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .common-right{
    width: calc(100% - 764px);
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 16px;
      .list-item{
        width: 210px;
        height: 207px;
        border-radius: 4px;
        background: #F8F8F8;
         cursor: pointer;
        .item-img{
          width: 210px;
          height: 128px;
          border-radius: 4px 4px 0px 0px;
        }
        .item-info{
          padding: 7px 16px;
          box-sizing: border-box;
          font-size: 14px;
          font-weight: bold;
          line-height: 22px;
          color: #1A1A1A;
          .item-info-title{
            height: 44px;
            line-height: 22px;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; 
            .item-info-lable{
              padding: 2px 4px;
              box-sizing: border-box;
              background: #FF3737;
              border-radius: 4px;
              line-height: 18px;
              color: #FFFFFF;
              font-size: 12px;
              margin-right: 2px;
              display: inline-block;
            }
          }
          .item-info-pub{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            color: #999999;
            font-family: Sans Medium;
            margin-top: 4px;
          }
        }
      }
      .list-item:nth-child(2n){
         margin-right: 0px;
      }
      .list-item:nth-child(3){
         margin-bottom: 0px;
      }
      .list-item:nth-child(4){
         margin-bottom: 0px;
      }
  }
}
</style>
