<template>
  <!-- 栏目组合 -->
  <div class="column">
    <div v-if="columnOne" class="column-left">
      <t-divider />
      <baseNav :title="columnOne.title" :id="columnOne.id"></baseNav>
      <div  class="main-item" v-for="(item) in columnOne.itemList.slice(0,4)" :key="item.id" @click="toDetailFn(item)">
        <div class="image"> <img :src="item.cover" class="item-img"/></div>
        <div class="item-info">
          <div class="item-info-title"><span v-if="item.label" class="item-info-lable">{{ item.label }}</span>{{ item.articleTitle }}</div>
          <div class="item-info-content-box">
            <div v-if="item.articleType !== 1" class="item-info-content">{{ escape2Html(getText(item.articleContent)) }}</div>
          </div>
          <div class="item-info-pub">
            <span>{{ item.creator }}</span>
            <span class="item-time">{{ formatTimestamp(item.publishTime) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="column-right">
      <div v-if="columnTwo" class="news-box">
        <div class="nav-title">
          <img :src="require('@/assets/nav-icon.svg')" class="left-img" />
          <span>{{ columnTwo.title }}</span>
        </div>
        <div class="news-item" v-for="(item,index) in columnTwo.itemList.slice(0,5)" :key="item.id" @click="toDetailFn(item)"> 
          <div class="news-item-title"><span v-if="item.label" class="news-item-lable">{{ item.label }}</span>{{ item.articleTitle }}</div>
          <t-divider v-if="index < columnTwo.itemList.slice(0,5).length - 1"/>
        </div>
      </div>
      <t-swiper v-if="swiperList && swiperList.length" class="swiper" :autoplay="true" :duration="300" :interval="2000" :navigation="{ showSlideBtn: 'never' }" :direction="direction">
        <t-swiper-item v-for="(it,index) in swiperList" :key="index" @click="toDetailSwiper(it.jumpLink)" class="swiper-item">
          <img :src="it.url" class="swiper-img"/>
        </t-swiper-item>
      </t-swiper>
    </div>
  </div>
</template>

<script setup>
/* 组件 */
import baseNav from './baseNav'

/* 工具 */
import { ref, defineProps, toRefs, computed } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();
import { formatTimestamp, escape2Html, getText } from '@/util/validate';

const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  }
});
const { info } = toRefs(props);
const swiperList = ref([]);

const columnOne = info.value.childrenList.find(x=> x.sort === 1 && x.itemList.length)
const columnTwo = info.value.childrenList.find(x => x.sort === 2 && x.itemList.length)
const swiperArr = info.value.childrenList.find(x => x.type === 4 && x.rotoList && x.rotoList.length)
if (swiperArr) {
  swiperList.value = swiperArr.rotoList
}

const direction = computed(() => {
  // 轮播方向
  return info.value.shape ? 'vertical' : 'horizontal';

});

const toDetailSwiper = (url) => {
  url && window.open(url)
}

const toDetailFn = (item) => {
   // 链接
  if (item.articleType) {
    item.articleContent && window.open(item.articleContent)
  } else {
    // 新闻
    window.open(router.resolve({ name: 'newsDetail',query: { id: item.id }}).href, '_blank')
  }
}

</script>
<style lang="scss" scoped>
.column {
  display:flex;
  width: 100%;
  justify-content: space-between;
  font-family: Sans Bold;
  margin-top: 24px;
  .column-left{
    width: calc(100% - 312px);
    :deep .t-divider{
      margin:0  0px 24px;
      border-left: 1px solid rgba(170, 170, 170, 0.2);
    }
    .main-item{
      display:flex;
      // flex-direction: column;
      justify-content: space-between;
      margin-top: 24px;
      cursor: pointer;

      .image{
        width: 267px;
        height: 150px;
        margin-right: 24px;
      }
      .item-img{
        width: 267px;
        height: 150px;
        border-radius: 4px;
        
      }
      .item-info{
        font-family: Sans Bold;
        font-size: 18px;
        font-weight: bold;
        color: #1A1A1A;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: calc(100% - 291px);

        .item-info-title{
          height: 60px;
          line-height: 30px;
          color: #1A1A1A;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; 
          margin-bottom: 8px;

          .item-info-lable{
            padding: 2px 4px;
            box-sizing: border-box;
            background: #FF3737;
            border-radius: 4px;
            line-height: 18px;
            color: #FFFFFF;
            font-size: 12px;
            margin-right: 2px;
            display: inline-block;
          }
        }
        .item-info-content-box{
          height: 48px;
          margin-bottom: 20px;
        }
        .item-info-content{
          height: 48px;
          line-height: 24px;
          font-size: 14px;
          letter-spacing: 0em;
          color: #666;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; 
           :deep blockquote {
            margin:0  !important;
          }
        }
        .item-info-pub{
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          color: #9E9E9E;
          .item-time{
            color: #999999;
            font-family: Sans Medium;
          }
        }
      }
    }
  }
  .column-right{
    width: 288px;
    margin-left: 24px;
    display:flex;
    flex-direction: column;

    .news-box{
      width: 288px;
      height: auto;
      border-radius: 4px;
      background: #F8F8F8;
      padding: 32px 16px;
      box-sizing: border-box;
      color: #1A1A1A;
      max-height: 544px;

      .nav-title{
        
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;

        .left-img{
          margin-right: 8px;
        }
      }

      .news-item{
         cursor: pointer;
        :deep .t-divider{
          margin: 24px 0px;
          border-left: 1px solid rgba(170, 170, 170, 0.2);
        }
        .news-item-title{
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          height: 48px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; 
        }

        .news-item-lable{
          padding: 2px 4px;
          box-sizing: border-box;
          background: #FF3737;
          border-radius: 4px;
          line-height: 18px;
          color: #FFFFFF;
          font-size: 12px;
          margin-right: 2px;
          display: inline-block;
          
        }
      }
    }
    .swiper{
      width: 288px;
      height: 192px;
      margin-top: 20px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      :deep .t-swiper__container {
        height: 192px!important;
      }
    }
    .swiper-item{
      cursor: pointer;
      width: 288px;
      height: 192px;
      border-radius: 4px;
    
      .swiper-img{
        width: 288px;
        height: 192px;
        border-radius: 4px;
      }
    }
  }
}
</style>
