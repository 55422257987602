import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import './style/common.scss'
import mitt from 'mitt'

const app = createApp(App)
app.config.globalProperties.$bus = mitt()
app.use(router)
app.use(TDesign)
app.use(VueViewer)
app.mount("#app");
