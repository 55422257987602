<template>
  <div v-if="info.itemList && info.itemList.length" class="column">
    <t-divider />
    <baseNav :title="info.title" :id="info.id"></baseNav>
    <div class="column-main">
      <div  class="main-item" v-for="(item) in info.itemList.slice(0,8)" :key="item.id" @click="toDetailFn(item)">
        <!-- <img :src="item.cover" class="item-img"/> -->
        <t-image
          :src="item.cover"
          fit="cover"
          position="center"
          class="item-img"
        />
        <div class="item-info">
          <div class="item-info-title"><span v-if="item.label" class="item-info-lable">{{ item.label }}</span>{{ item.articleTitle }}</div>
          <div class="item-info-pub">
            <span>{{ item.creator }}</span>
            <span class="item-time">{{ formatTimestamp(item.publishTime) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* 组件 */
import baseNav from './baseNav'

/* 工具 */
import { ref, defineProps, toRefs } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();
import { formatTimestamp } from '@/util/validate';

const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  }
});
const { info } = toRefs(props);

const toDetailFn = (item) => {
   // 链接
  if (item.articleType) {
    item.articleContent && window.open(item.articleContent)
  } else {
    // 新闻
    window.open(router.resolve({ name: 'newsDetail',query: { id: item.id }}).href, '_blank')
  }
}
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  font-family: Sans Bold;
  .column-main{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 24px;
    margin-top: 24px;
    .main-item{
      width: 568px;
      display: flex;
       cursor: pointer;
      .item-img{
        width: 237px;
        height: 139px;
        border-radius: 4px;
        margin-right: 16px;
      }
      .item-info{
        flex: 1;
        font-family: Sans Bold;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
        color: #1A1A1A;
        .item-info-title{
          height: 90px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; 
          margin-bottom: 19px;
          margin-top: 4px;
          .item-info-lable{
            padding: 2px 4px;
            box-sizing: border-box;
            background: #FF3737;
            border-radius: 4px;
            line-height: 18px;
            color: #FFFFFF;
            font-size: 12px;
            margin-right: 2px;
            display: inline-block;
          }
        }
        .item-info-pub{
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          color: #9E9E9E;
          .item-time{
            color: #999999;
            font-family: Sans Medium;
          }
        }
      }
    }
    .main-item:nth-child(2n){
      margin-right: 0px;
    }
  }
}
</style>
