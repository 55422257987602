<template>
  <div class="content">
    <router-view />
  </div>
</template>

<script setup>
/* hooks */
import useTokenLogic from "@/views/hooks/useTokenLogic.js";
useTokenLogic()

</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  --theme: rgb(241, 107, 95);
  font-size: 14px;
}
*{
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.content {
  /* box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll; */
}
</style>