import request from "@/api/axios";
import qs from "querystring";
import { apiList as api } from "./apiList";

//  首页数据列表查询(未登录)
export function getHomepage(data) {
  return request({
    url: api.home.getHomepage,
    method: "post",
    data
  });
}


 // 根据栏目查询文章列表（未登录）
export function getNews(id) {
  return request({
    url: api.home.getNews + id,
    method: "get"
  });
}

//热门排行列表查询（未登录）
export function getHot() {
  return request({
    url: api.home.getHot,
    method: "get"
  });
}


//根据文章ID查看详情（未登录）
export function getNewsDetail(data) {
  return request({
    url: api.home.getNewsDetail,
    method: "post",
    data
  });
}

//记录文章访问量（未登录）
export function recordAccess(id) {
  return request({
    url: api.home.recordAccess  + id,
    method: "get"
  });
}


// 首页群组列表
export function getGroupList(data) {
  return request({
    url: api.home.getGroupList,
    method: "post",
    data
  });
}

// 获取应用列表
export function getApplication(params) {
  return request({
    url: api.home.getApplication,
    method: "get",
    params
  });
}

export function detailAndStatus(id) {
  //获取应用详情无需鉴权
  return request({
    url: api.home.detailAndStatus + id,
    method: "get",
  });
}

export function detailAndStatusAuth(id) {
  //获取应用详情
  return request({
    url: api.home.detailAndStatusAuth + id,
    method: "get",
  });
}

export function createApp(id) {
  //添加应用
  return request({
    url: api.home.createApp + id,
    method: "post",
  });
}
export function appLogs(id) {
  //应用日志
  return request({
    url: api.home.appLogs + id,
    method: "get",
  });
}
export function strategyApp(data) {
  //会员信息
  return request({
    url: api.home.strategyApp,
    method: "post",
    data,
  });
}

// 加入指定群组
export function joinGroup(data) {
  return request({
    url: api.home.joinGroup,
    method: "post",
    data
  });
}

// /根据类型获取封面
export function getCover(params) {
  return request({
    url: api.home.getCover,
    method: "get",
    params
  });
}
