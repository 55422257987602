<template>
  <div class="home">
    <div class="container">
      <div v-for="(item) in columnList" :key="item.id" class="container-item">
        <!--item.type  0：头部固定轮播图栏目，1：栏目样式1 ，2：栏目样式二 ，3：栏目样式3，4：轮播图， 5：栏目组合样式1 -->
        <common v-if="!item.type" :info="item"></common>
        <columnOne v-if="item.type === 1" :info="item"></columnOne> 
        <columnTwo v-if="item.type === 2" :info="item"></columnTwo>
        <columnThree v-if="item.type === 3" :info="item"></columnThree>
        <swiper v-if="item.type === 4" :info="item"></swiper>
        <column v-if="item.type === 5" :info="item"></column>
      </div>
    </div>
  </div>
</template>

<script setup>
/* 组件 */
import common from './component/common'
import column from './component/column'
import columnOne from './component/columnOne'
import columnTwo from './component/columnTwo'
import columnThree from './component/columnThree'
import swiper from './component/swiper'

/* api */
import { getHomepage } from '@/api/home'

/* 工具 */
import { ref } from "vue"
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

/*变量 */
const columnList = ref([])

// 获取首页数据
const getHomeInfo = () => {
  getHomepage({ category: 0 }).then(res => {
    columnList.value = res.data.data
  })
}
getHomeInfo()

</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  
  .container{
    height: 100%;
    width: 1200px;
    margin: 0 auto 24px;

    .container-item{
       width: 1200px;
    }
  }

}
</style>
