/* 登录等调整逻辑 hook */

/* 工具 */
import { ref, getCurrentInstance } from "vue"
import { useRouter, useRoute } from "vue-router"
import { setStore, getStore, clearStore, removeStore } from '@/util/store'

/* api */
import { getTokenUser, getCheckToken, getAccountInfo, getUserInfo } from "@/api/user.js"

export default async function () {
  /* 变量 */
  const { $bus } = getCurrentInstance().appContext.config.globalProperties
  const route = useRoute()
  const router = useRouter()

  const envName = ['dev', 'test', 'pre'].find(e => location.host.includes(e)) || '' // 环境变量
  const token = getStore({ name: "access_token" }) || null
  const userInfo = getStore({ name: "accessInfo" }) || {} // 用户信息

  /* 事件 */
  // 转化地址栏参数
  const toQueryInfo = (query) => Array.from(new URLSearchParams(query))
    .reduce((p, [k, v]) => Object.assign({}, p, { [k]: p[k] ? (Array.isArray(p[k]) ? p[k] : [p[k]]).concat(v) : v }), {})

  // 获取地址栏参数
  const getQueryInfo = (key) => {
    const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i')
    const r = window.location.search.substr(1).match(reg)
    if (r != null) {
      return unescape(r[2])
    }
    return null
  }
  // 通过token获取用户信息
  const getUserInfoByToken = (token) => {
    return new Promise(resolve => {
      getTokenUser(token).then(res => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        }
      })
    })
  }
  // 通过userId获取用户信息并且保存信息
  const saveUserInfo = (userInfo) => {
    const userId = userInfo.user_id
    const promises = [getAccountInfo(userId), getUserInfo(userId)]
    return new Promise(resolve => {
      Promise.all(promises).then(arr => {
        const res1 = arr[0]
        const res2 = arr[1]
        if (res1.data.code === 0) {
          const accessInfo = { ...userInfo, ...res1.data.data }
          setStore({ name: "accessInfo", content: accessInfo })
        }
        if (res2.data.code === 0) {
          const userInfo = res2.data.data
          setStore({ name: "userInfo", content: userInfo, })
        }
        $bus.emit('saveUserInfo')
        resolve()
      })
    })
  }

  const toLoginPage = () => {
    const origin = envName ? `${envName}login.huixuecloud.cn` : 'login.zhixuehuijiao.cn'
    const query = `redirect_url=${window.location.origin}${window.location.pathname}`
    const href = `//${origin}?${query}`
    window.location.href = href
  }

  const getLoginInfo = async (token) => {
    const userInfo = await getUserInfoByToken(token)
    if (userInfo) {
      setStore({ name: 'access_token', content: userInfo.access_token })
      setStore({ name: 'accessInfo', content: userInfo })
      await saveUserInfo(userInfo)
    }
  }

  // 跳转页面
  const toPage = () => {
    const routePath = getStore({ name: "routePath" })
    const routeQuery = getStore({ name: "routeQuery" })
    if (routePath) {
      if (routeQuery) {
        router.push({ path: routePath, query: routeQuery })
      } else {
        router.push(routePath)
      }
      removeStore({ name: "routePath" })
      removeStore({ name: "routeQuery" })
    }
  }

  /* 逻辑 */
  const saveVipInfo = () => {
    const queryInfo = toQueryInfo(location.search)
    if (queryInfo.subject) {
      const { period, subject, version } = queryInfo
      const localData = JSON.parse(localStorage.getItem('vip-service-info') || '{}')
      const info = { ...localData }
      period !== '0' && (info.period = period)
      subject !== '0' && (info.subject = subject)
      version !== '0' && (info.version = version)
      localStorage.setItem('vip-service-info', JSON.stringify(info))
    }
  }
  saveVipInfo()

  // 判断是否有无token
  if (!token) {
    const isLogin = getQueryInfo('isLogin')
    // 判断是否跳转过来之前是已登录
    if (isLogin === 'true') {
      const queryInfo = toQueryInfo(location.search)
      queryInfo.isLogin && delete queryInfo.isLogin
      setStore({ name: "routePath", content: location.pathname })
      setStore({ name: "routeQuery", content: queryInfo })

      toLoginPage()
    } else {
      const queryToken = getQueryInfo('token')
      // 如果路由有token
      if (queryToken) {
        getLoginInfo(queryToken)
      }
      toPage()
    }
  } else {
    getCheckToken(token).then(res => {
      getLoginInfo(token)
      toPage()
    }).catch(err => {
      getQueryInfo('isLogin') === 'true' && toLoginPage()
    })
  }

  window.overtime = () => {
    clearStore({ excludes: ['vip-service-info'] })
    $bus.emit('update')
  }
}
