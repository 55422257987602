/* 验证pad还是pc */
export const vaildatePc = function() {
  const userAgentInfo = navigator.userAgent
  const Agents = ['Android', 'iPhone',
    'SymbianOS', 'Windows Phone',
    'iPad', 'iPod'
  ]
  let flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}


/**
 * 判断是否为空
 */
export function validateNull(val) {
  if (val === null) {
    return true
  }
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}

export const escape2Html = str => {
  const arrEntities = {
    'lt': '<',
    'gt': '>',
    'nbsp': ' ',
    'amp':
    '&',
    'quot': '"',
    'mdash': '—',
    "ldquo": '“',
    "rdquo": '”',
    "rsquo": '’',
    "lsquo": '‘',
    "permil": '‰',
    "euro": '€',
    "le": '≤',
    "ge": '≥',
    "ne": '≠',
    "\\n": ' ',
    "middot": '·',
    "trade": '™'
  }
  return str.replace(/&(lt|gt|nbsp|amp|quot|mdash|ldquo|rdquo|rsquo|lsquo|permil|euro|le|ge|ne|middot|trade);|(\\n)/gi, (all, t, d) => {
    return arrEntities[t] || arrEntities[d]
  })
}

/**
 * 文章时间格式化
 */
export const formatTimestamp = timestamp => {
  var now = new Date();
  var date = new Date(+timestamp);
  var diff = now - date;
  var seconds = Math.floor(diff / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  if (days < 1) {
    if (days === 0 && hours < 1) {
      return minutes + '分钟前'
    } else {
      return hours + '小时前';
    }
  } else if (days < 7) {
    return days + '天前';
  } else {
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;
  }
}

/**
 * 文章内容展示处理
 */
export const getText = (text) => {
  // 去除HTML标签
  var cleanText = text.replace(/<(?:.|\n)*?>/gm, '');
  // 去除图片和视频标签
  cleanText = cleanText.replace(/\[img\].*?\[\/img\]/gi, '');
  cleanText = cleanText.replace(/\[video\].*?\[\/video\]/gi, '');
  return cleanText;
}